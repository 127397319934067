// This must be the first line in src/index.js
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
// import ReactDOMServer from "react-dom/server";
import App from "./App";
import "./index.module.scss";
// import registerServiceWorker from './registerServiceWorker';

// ReactDOM.hydrate(<App />, document.getElementById("root"));
ReactDOM.render(<App />, document.getElementById("root"));

// const serverSideRender = ReactDOMServer.renderToString(<App />);
// console.log("serverSideRender", serverSideRender);
// registerServiceWorker();
