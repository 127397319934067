import React from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import styles from "./Textarea.module.scss";

function Textarea({ id, label, name, value, onChange }) {
  return (
    <div className={styles.container}>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      <textarea
        className={styles.textarea}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

Textarea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

Textarea.defaultProps = {
  id: "",
  label: "default label",
  name: "default label",
  value: "default label",
  onChange: noop
};

export default Textarea;
