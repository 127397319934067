import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import logo from "../../images/lastpass-solutions-logo.png";
import styles from "./Header.module.scss";

function Header({ scrollTo }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  function toggleMenu() {
    setIsMobileMenuOpen(prevState => !prevState);
  }

  function onMenuItemClick(e) {
    toggleMenu();
    scrollTo(e);
  }

  return (
    <header id="header" className={styles.header}>
      <div className={styles.headerContent}>
        <img className={styles.logo} src={logo} alt="Lastpass solutions logo" />

        <nav aria-label="navigation">
          <ul
            className={styles.navigation}
            role="menubar"
            aria-label="Mobile navigation"
          >
            <li role="none">
              <a
                role="menuitem"
                className={styles.navLink}
                href="#about"
                onClick={scrollTo}
              >
                About us
              </a>
            </li>
            <li role="none">
              <a
                role="menuitem"
                className={styles.navLink}
                href="#services"
                onClick={scrollTo}
              >
                Services
              </a>
            </li>
            <li role="none">
              <a
                role="menuitem"
                className={styles.navLink}
                href="#clients"
                onClick={scrollTo}
              >
                Clients
              </a>
            </li>
            <li role="none">
              <a
                role="menuitem"
                className={styles.navLink}
                href="#contact"
                onClick={scrollTo}
              >
                Contact us
              </a>
            </li>
          </ul>
        </nav>

        <nav
          className={styles.mobileNavigationContainer}
          aria-label="Mobile navigation"
        >
          <button
            type="button"
            onClick={toggleMenu}
            className={cx(styles.mobileMenuToggle, {
              [styles.isOpen]: isMobileMenuOpen
            })}
            aria-controls="mobile-navigation"
            aria-expanded={isMobileMenuOpen}
          >
            <span className={styles.mobileMenuToggleIcon}>
              Toggle navigation
            </span>
          </button>

          <ul
            id="mobile-navigation"
            className={cx(styles.mobileNavigation, {
              [styles.isOpen]: isMobileMenuOpen
            })}
            role="menubar"
            aria-label="Mobile navigation"
          >
            <li role="none">
              <a
                role="menuitem"
                className={styles.navLink}
                href="#about"
                onClick={onMenuItemClick}
              >
                About us
              </a>
            </li>
            <li role="none">
              <a
                role="menuitem"
                className={styles.navLink}
                href="#services"
                onClick={onMenuItemClick}
              >
                Services
              </a>
            </li>
            <li role="none">
              <a
                role="menuitem"
                className={styles.navLink}
                href="#clients"
                onClick={onMenuItemClick}
              >
                Clients
              </a>
            </li>
            <li role="none">
              <a
                role="menuitem"
                className={styles.navLink}
                href="#contact"
                onClick={onMenuItemClick}
              >
                Contact us
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

Header.propTypes = {
  scrollTo: PropTypes.func.isRequired
};

Header.defaultProps = {};

export default Header;
