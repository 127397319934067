import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import noop from "lodash/noop";
import styles from "./Button.module.scss";

function Button({ id, children, buttonStyle, onClick, className, loading }) {
  return (
    <button
      id={id}
      type="button"
      className={cx(className, {
        [styles.red]: buttonStyle === "red",
        [styles.redTransparent]: buttonStyle === "redTransparent"
      })}
      onClick={() => onClick()}
    >
      {loading ? "loading" : children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  buttonStyle: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};

Button.defaultProps = {
  buttonStyle: "red",
  id: "",
  className: "",
  onClick: noop,
  loading: false
};

export default Button;
