import axios from "axios";

const commonSettings = {
  headers: {
    common: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }
};

function initializeApiInstance(baseURL) {
  const axiosInstance = axios.create({
    ...commonSettings,
    baseURL
  });

  return axiosInstance;
}

class Api {
  constructor() {
    this.lpApi = initializeApiInstance();
  }
}

const api = new Api();

export default api;
