import React from "react";
import styles from "./Footer.module.scss";

function Footer() {
  const today = new Date();

  return (
    <footer className={styles.container}>
      &copy; {today.getFullYear()} Lastpass solutions All rights reserved
    </footer>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
