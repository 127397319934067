import React, { useState } from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import qs from "qs";
import get from "lodash/get";
import {
  faCompressArrowsAlt,
  faDraftingCompass,
  faFileCode,
  faTasks,
  faCloudUploadAlt,
  faCogs
} from "@fortawesome/free-solid-svg-icons";
import { animateScroll as scroll } from "react-scroll";
import Button from "./components/Button/Button";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import downArrow from "./images/down-arrow.png";
import styles from "./App.module.scss";
import Input from "./components/Input/Input";
import Textarea from "./components/Textarea/Textarea";
import api from "./utils/api";
import {
  validateEmail,
  validateFirstName,
  validateLastName
} from "./utils/helpers";

function App() {
  const initialFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    message: ""
  };

  const [values, setValues] = useState(initialFormValues);

  const [submitting, setSubmitting] = useState(false);
  const [submitResult, setSubmitResult] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const validators = {
    firstName: validateFirstName,
    lastName: validateLastName,
    email: validateEmail
  };

  function scrollTo(e) {
    const targetElement = document.getElementById(
      `${e.target.href.split("#")[1]}-lp`
    );
    const header = document.getElementById("header");
    if (targetElement) {
      scroll.scrollTo(targetElement.offsetTop - header.offsetHeight, {
        duration: 500,
        delay: 0,
        smooth: true,
        spy: true
      });

      targetElement.tabIndex = -1;
      targetElement.focus();
    }
  }

  function updateErrorMessage(errorMessages) {
    setValues(prevState => ({
      ...prevState,
      ...errorMessages
    }));
  }

  async function onSubmit() {
    const defaultErrorMessage =
      "It appeared there was an error, please try again.";

    const emailErrorMessage = validateEmail(values.email);
    const firstNameErrorMessage = validateFirstName(values.firstName);
    const lastNameErrorMessage = validateLastName(values.lastName);
    let errorMessages = {};

    if (firstNameErrorMessage) {
      errorMessages = {
        ...errorMessages,
        firstNameError: firstNameErrorMessage
      };
    }

    if (lastNameErrorMessage) {
      errorMessages = {
        ...errorMessages,
        lastNameError: lastNameErrorMessage
      };
    }

    if (emailErrorMessage) {
      errorMessages = {
        ...errorMessages,
        emailError: emailErrorMessage
      };
    }

    if (firstNameErrorMessage || lastNameErrorMessage || emailErrorMessage) {
      updateErrorMessage(errorMessages);
      return;
    }

    try {
      setSubmitting(true);
      setSubmitResult(null);
      setSubmitError(null);
      const response = await api.lpApi.post("/sendMail", qs.stringify(values));
      const isSuccess = get(response, "data.success");

      if (isSuccess) {
        setSubmitResult(response.data);
        setValues(initialFormValues);
      } else {
        const errorMessage = get(response, "data.msg");
        setSubmitError(errorMessage || defaultErrorMessage);
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setSubmitError(defaultErrorMessage);
    }
  }

  function onInputChanged(event) {
    const { name, value } = event.target;

    const errorMessage = validators[name] && validators[name](value);

    setValues(prevState => ({
      ...prevState,
      [name]: value,
      [`${name}Error`]: errorMessage
    }));
  }

  return (
    <>
      <Header scrollTo={scrollTo} />

      <main>
        <div className={styles.banner}>
          <div className={styles.bannerContent}>
            <h2 className={styles.bannerTitle}>
              Empower your business with trusted Technology Partner
            </h2>

            <a className={styles.bannerCta} href="#about" onClick={scrollTo}>
              Show me more!
            </a>

            <a
              className={styles.downArrow}
              href="#about"
              aria-label="Skip to About us"
              onClick={scrollTo}
            >
              <img src={downArrow} alt="" />
            </a>
          </div>
        </div>

        <div id="about-lp" className={cx(styles.section, styles.about)}>
          <h2>Who we are</h2>
          <p>
            Lastpass solutions is a Sydney-based web and app consultancy
            company. We help our clients succeed by identifying opportunities
            and delivering solutions which are cost effective, innovative and
            user friendly.
          </p>
        </div>

        <div className={styles.servicesBackground}>
          <div id="services-lp" className={cx(styles.section, styles.services)}>
            <h2>What we do</h2>
            <p>
              With a team of passionate web and app artisans, we are capable of
              building custom web and app applications. Our services include:{" "}
            </p>

            <div className={styles.servicesContent}>
              <div className={styles.serviceColumn}>
                <FontAwesomeIcon icon={faCompressArrowsAlt} />
                <h3>Planning</h3>

                <p>Requirements gathering, Business planning and Research.</p>
              </div>

              <div className={styles.serviceColumn}>
                <FontAwesomeIcon icon={faDraftingCompass} />
                <h3>Design</h3>

                <p>
                  UI/UX design, prototyping, user testing, feedback gathering
                  and prototyping refinement.
                </p>
              </div>

              <div className={styles.serviceColumn}>
                <FontAwesomeIcon icon={faFileCode} />
                <h3>Development</h3>

                <p>
                  Architecture design, Frontend development, API development and
                  Backend development.
                </p>
              </div>

              <div className={styles.serviceColumn}>
                <FontAwesomeIcon icon={faTasks} />
                <h3>Testing</h3>

                <p>
                  Manual testing, automation testing such as unit testing,
                  functional testing, end to end testing, cross devices testing
                  and Bug fixes.
                </p>
              </div>

              <div className={styles.serviceColumn}>
                <FontAwesomeIcon icon={faCloudUploadAlt} />
                <h3>Deployment</h3>

                <p>
                  Build pipeline setup, day to day automation, auto deployment
                  with CI/CD pipeline
                </p>
              </div>

              <div className={styles.serviceColumn}>
                <FontAwesomeIcon icon={faCogs} />
                <h3>Maintenance & Support</h3>

                <p>
                  Data backup, code backup, 24x7 monitoring system set up,
                  troubleshooting and bug fixes.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="clients-lp" className={cx(styles.section, styles.clients)}>
          <h2>Clients</h2>
          <p>
            Our clients include startups, not-for-profits organisations, digital
            media agencies, mass media and publishing companies, government
            departments, financial institutions, online wagering companies,
            australian telecommunications and australian airlines.
          </p>
        </div>

        <div className={styles.contactBackground}>
          <div id="contact-lp" className={cx(styles.section, styles.contact)}>
            <h2>Contact us</h2>
            <div className={styles.formContainer}>
              <div className={styles.calloutContainer}>
                <h3 className={styles.callout}>
                  Be up-to-date and start{" "}
                  <strong>professional digital application</strong> right now!
                </h3>
              </div>

              <form className={styles.contactForm}>
                {submitResult && submitResult.success && (
                  <div className={styles.formRow}>
                    <div className={styles.successContainer}>
                      <strong>Success:</strong> {submitResult.msg}
                    </div>
                  </div>
                )}

                {!!submitError && (
                  <div className={styles.formRow}>
                    <div className={styles.errorContainer}>
                      <strong>Error:</strong> {submitError}
                    </div>
                  </div>
                )}

                <div className={styles.formRow}>
                  <Input
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    value={values.firstName}
                    onChange={onInputChanged}
                    errorMessage={values.firstNameError}
                  />
                  <Input
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    value={values.lastName}
                    onChange={onInputChanged}
                    errorMessage={values.lastNameError}
                  />
                </div>

                <div className={styles.formRow}>
                  <Input
                    id="email"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={onInputChanged}
                    errorMessage={values.emailError}
                  />
                </div>

                <div className={styles.formRow}>
                  <Textarea
                    id="message"
                    label="Message (Optional)"
                    name="message"
                    value={values.message}
                    onChange={onInputChanged}
                  />
                </div>

                <div className={styles.formRow}>
                  <Button
                    id="submitButton"
                    onClick={onSubmit}
                    buttonStyle="red"
                    loading={submitting}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}

App.propTypes = {};

App.defaultProps = {};

export default App;
