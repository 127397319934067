import React from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import cx from "classnames";
import styles from "./Input.module.scss";

function Input({ id, label, name, value, onChange, errorMessage }) {
  return (
    <div
      className={cx(styles.container, {
        [styles.error]: !!errorMessage
      })}
    >
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      <input
        className={styles.input}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
      />
      {!!errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func
};

Input.defaultProps = {
  id: "",
  name: "",
  value: "",
  errorMessage: null,
  label: "default label",
  onChange: noop
};

export default Input;
