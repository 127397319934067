export const validateFirstName = name => {
  return name ? "" : "Please enter your first name";
};

export const validateLastName = name => {
  return name ? "" : "Please enter your last name";
};

export const validateEmail = email => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

  if (!email) {
    return "please enter your email.";
  }

  if (!regex.test(email)) {
    return "Email is invalid.";
  }

  return "";
};
